<template>
  <div>
    <v-btn
      icon="icon"
      @click="showSnackbar = true"
    >
      <v-icon>mdi-help-circle-outline</v-icon>
    </v-btn>
    <v-snackbar
      v-model="showSnackbar"
      absolute="absolute"
      top="top"
      vertical="vertical"
      color="primary"
    >
      <span> ※問題や解説の内容に関するご質問は、論点質問フォーム（受講生専用サイトのMenu＞各種相談予約/論点質問等＞論点の質問フォーム）よりお願い致します。</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          small="small"
          text="text"
          @click="showSnackbar = false"
        >
          閉じる
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>

export default {
  name: "CPAContact",
  data() {
    return {
      showSnackbar: false
    }
  },
}
</script>

<style scoped>

</style>
