<template>
  <div class="my-auto adjust-icon">
    <v-tooltip top="top">
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          size="30px"
          :color="memo ? 'green' : 'grey'"
          v-bind="attrs"
          @click="showMemo = true"
          v-on="on"
        >
          mdi-file-edit
        </v-icon>
      </template>メモ
    </v-tooltip>
    <v-dialog
      v-model="showMemo"
      max-width="800px"
    >
      <v-card>
        <v-card-title>メモ</v-card-title>
        <v-card-text>
          <!-- 以下 v-ifはページが変わったさいにauto-growをリセットするた-->
          <v-textarea
            v-if="showMemo"
            v-model="memoEdited"
            class="mb-3"
            outlined="outlined"
            auto-grow="auto-grow"
            rows="5"
            hide-details="hide-details"
            @blur="updateMemo"
          />
          <v-btn
            primary="primary"
            small="small"
            outlined="outlined"
            block="block"
            @click="showMemo=false"
          >
            閉じる
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="showSuccessSnackbar"
      color="success"
    >
      メモを保存しました
      <template v-slot:action="{ attrs }">
        <v-icon
          v-bind="attrs"
          dark="dark"
          @click="showSuccessSnackbar = false"
        >
          mdi-close
        </v-icon>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="showFailureSnackbar"
      color="error"
    >
      保存に失敗しました。再度お試しください。
      <template v-slot:action="{ attrs }">
        <v-icon
          v-bind="attrs"
          dark="dark"
          @click="showFailureSnackbar = false"
        >
          mdi-close
        </v-icon>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "Memo",
  props: {
    memo: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showMemo: false,
      editMode: false,
      memoEdited: '',
      showSuccessSnackbar: false,
      showFailureSnackbar: false,
    }
  },
  computed: {
    ...mapGetters(["responseStatus"]),
  },
  watch: {
    memo: function () {
      this.memoEdited = this.memo;
    },
  },
  mounted() {
    this.memoEdited = this.memo;
  },
  methods: {
    async updateMemo() {
      if (this.memoEdited === this.memo) return;

      await this.$store.dispatch('updateQuestionMemo', this.memoEdited);
      if (this.responseStatus) {
        this.showSuccessSnackbar = true;
        this.showMemo = false;
      } else {
        this.showFailureSnackbar = true;
      }
    }
  },
}
</script>

<style scoped>
.adjust-icon {
  display: flex;
  justify-content: center;
  width: 36px;
}

</style>
