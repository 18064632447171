<template>
  <!-- eslint-disable vue/no-v-text-v-html-on-component vue/no-v-html -->

  <v-card class="py-1 px-0">
    <v-card-text
      class="law-text"
      v-html="lawText"
    />
  </v-card>
  <!-- eslint-enable vue/no-v-text-v-html-on-component vue/no-v-html -->
</template>

<script>
export default {
  name: "LawModal",
  props: {
    lawText: { type: String, default: '' }
  }
}
</script>

<style scoped>
.law-text >>> h1 {
  padding: 0.25em 0.5em; /*上下 左右の余白*/
  color: #494949; /*文字色*/
  border-left: solid 5px #7db4e6; /*左線*/
  font-size: large;
  margin: 1em 0.5em 1em 0.5em;
}
</style>
